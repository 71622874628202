import React from 'react';
import { Box, Button, Link, TextField, styled, CircularProgress, Stack } from '@mui/material';
import { ILoginformValues } from '#/Utils/types';
const Input = styled(TextField)(() => `
    background-color: rgba(33, 33, 33, 0.08);
  `);
const CircularProgressIcon = styled(CircularProgress)(({
  theme
}) => ({
  color: theme.text.primary.high,
  marginRight: theme.spacing(1)
}));
export interface LoginFormProps {
  handleSubmit: React.FormEventHandler<HTMLFormElement>;
  handleInputChange: React.ChangeEventHandler;
  emailIsValid: Boolean;
  formIsValid: Boolean;
  formValues: ILoginformValues;
  isLoading: Boolean;
}
export const LoginForm: React.FC<LoginFormProps> = props => {
  const {
    handleSubmit,
    handleInputChange,
    emailIsValid,
    formValues,
    formIsValid,
    isLoading
  } = props;
  return <Box component="form" onSubmit={handleSubmit} sx={{
    marginTop: theme => theme.spacing(4)
  }} data-sentry-element="Box" data-sentry-component="LoginForm" data-sentry-source-file="LoginForm.tsx">
      <Stack spacing={4} data-sentry-element="Stack" data-sentry-source-file="LoginForm.tsx">
        <Input error={!emailIsValid || !formValues.email} required fullWidth id="email" label="Email Address" name="email" autoComplete="email" variant="filled" onChange={handleInputChange} data-sentry-element="Input" data-sentry-source-file="LoginForm.tsx" />
        <Input error={!formValues.password} required fullWidth name="password" label="Password" type="password" id="password" variant="filled" autoComplete="new-password" onChange={handleInputChange} data-sentry-element="Input" data-sentry-source-file="LoginForm.tsx" />
        <Button type="submit" fullWidth variant="contained" className="mt-lg" disabled={!formIsValid} data-sentry-element="Button" data-sentry-source-file="LoginForm.tsx">
          {isLoading && <CircularProgressIcon size={14} />} Login
        </Button>
        <Link href="/forgot-password" underline="none" data-sentry-element="Link" data-sentry-source-file="LoginForm.tsx">
          Forgot Password
        </Link>
      </Stack>
    </Box>;
};