import { Box, Stack, Typography, styled } from '@mui/material';
import { CertifyOsWhiteLogo, RegisterTickIcon } from '../Icons';
const Text = styled(Typography)(({
  theme
}) => ({
  color: '#fff'
}));
const LogoWrapper = styled(Box)(({
  theme
}) => ({
  width: '100%',
  padding: `${theme.spacing(8)} ${theme.spacing(8)} 0`
}));
const Container = styled(Box)({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  height: '100%'
});
const IntroStack = styled(Stack)(({
  theme
}) => ({
  padding: `${theme.spacing(6)} ${theme.spacing(8)}`,
  [theme.breakpoints.up('md')]: {
    padding: `0 ${theme.spacing(8)}`,
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%'
  }
}));
export const IntroSection = () => {
  return <Container data-sentry-element="Container" data-sentry-component="IntroSection" data-sentry-source-file="IntroSection.tsx">
      <LogoWrapper data-sentry-element="LogoWrapper" data-sentry-source-file="IntroSection.tsx">
        <CertifyOsWhiteLogo data-sentry-element="CertifyOsWhiteLogo" data-sentry-source-file="IntroSection.tsx" />
      </LogoWrapper>
      <IntroStack spacing={2} data-sentry-element="IntroStack" data-sentry-source-file="IntroSection.tsx">
        <Text variant="h3" data-sentry-element="Text" data-sentry-source-file="IntroSection.tsx">
          The fastest way to license, credential, and monitor your provider
          network
        </Text>
        <Text variant="h5" className="mt-2xxl" data-sentry-element="Text" data-sentry-source-file="IntroSection.tsx">
          <RegisterTickIcon className="mr-sm" data-sentry-element="RegisterTickIcon" data-sentry-source-file="IntroSection.tsx" /> CVO credentialing and
          monitoring
        </Text>
        <Text variant="h5" data-sentry-element="Text" data-sentry-source-file="IntroSection.tsx">
          <RegisterTickIcon className="mr-sm" data-sentry-element="RegisterTickIcon" data-sentry-source-file="IntroSection.tsx" /> Provider licensing
        </Text>
        <Text variant="h5" data-sentry-element="Text" data-sentry-source-file="IntroSection.tsx">
          <RegisterTickIcon className="mr-sm" data-sentry-element="RegisterTickIcon" data-sentry-source-file="IntroSection.tsx" /> Health plan enrollment
        </Text>
        <Text variant="h5" data-sentry-element="Text" data-sentry-source-file="IntroSection.tsx">
          <RegisterTickIcon className="mr-sm" data-sentry-element="RegisterTickIcon" data-sentry-source-file="IntroSection.tsx" /> Provider data enrichment
        </Text>
      </IntroStack>
    </Container>;
};