import React, { useEffect } from 'react';
import { Grid, Typography, Box, Card, CardContent, styled } from '@mui/material';
import { LoginForm } from './LoginForm';
import { ILoginformValues } from '#/Utils/types';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, AppState } from '#/redux/reducers/rootReducer';
import { LoginError } from '#/redux/actions/Auth/Auth';
import { IntroSection } from '../IntroSection';
export interface LoginProps {
  handleSubmit: React.FormEventHandler<HTMLFormElement>;
  handleInputChange: React.ChangeEventHandler;
  emailIsValid: Boolean;
  formIsValid: Boolean;
  formValues: ILoginformValues;
  isLoading: Boolean;
}
const RightSubContainerBox = styled(Grid)(({
  theme
}) => ({
  backgroundColor: theme.palette?.background?.paper
}));
export const Login: React.FC<LoginProps> = props => {
  const {
    handleSubmit,
    handleInputChange,
    emailIsValid,
    formValues,
    formIsValid,
    isLoading
  } = props;
  const dispatch = useDispatch<AppDispatch>();
  const authState = useSelector((state: AppState) => state.auth);
  useEffect(() => {
    // reset error
    dispatch(LoginError());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <Grid container style={{
    minHeight: '100vh'
  }} data-sentry-element="Grid" data-sentry-component="Login" data-sentry-source-file="Login.tsx">
      <Grid item xs={12} md={6} style={{
      background: '#79709E'
    }} data-sentry-element="Grid" data-sentry-source-file="Login.tsx">
        <IntroSection data-sentry-element="IntroSection" data-sentry-source-file="Login.tsx" />
      </Grid>
      <Grid item container xs={6} sx={theme => ({
      background: theme.palette.background.default,
      display: 'flex'
    })} justifyContent="center" alignItems="center" data-sentry-element="Grid" data-sentry-source-file="Login.tsx">
        <RightSubContainerBox item xs={10} className="card-md" data-sentry-element="RightSubContainerBox" data-sentry-source-file="Login.tsx">
          <Grid container justifyContent="center" data-sentry-element="Grid" data-sentry-source-file="Login.tsx">
            <Grid item xs={8} data-sentry-element="Grid" data-sentry-source-file="Login.tsx">
              <Typography variant="h3" className="mb-lg" data-sentry-element="Typography" data-sentry-source-file="Login.tsx">
                Login
              </Typography>
              {authState.errorMessage && <Box marginBottom={3}>
                  <Typography variant="body2" color="error">
                    {authState.errorMessage}
                  </Typography>
                </Box>}
              <LoginForm handleSubmit={handleSubmit} handleInputChange={handleInputChange} emailIsValid={emailIsValid} formValues={formValues} formIsValid={formIsValid} isLoading={isLoading} data-sentry-element="LoginForm" data-sentry-source-file="Login.tsx" />
            </Grid>
          </Grid>
        </RightSubContainerBox>
        {/* <Box sx={{ padding: (theme) => theme.spacing(8) }}>
          <Card style={{ minWidth: 500 }}>
            <CardContent sx={{ padding: (theme) => theme.spacing(4) }}>
              
            </CardContent>
          </Card>
         </Box> */}
      </Grid>
    </Grid>;
};